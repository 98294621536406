import React from "react";
import {
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from "soapbox/components/ui";

const Members = ({ space, showMessages }: any) => {
  const menus = [
    {
      title: "Mute",
      action: () => console.log("Mute"),
    },
    {
      title: "Remove as speaker",
      action: () => console.log("Remove as speaker"),
    },
    {
      title: "Send Private Message",
      action: () => console.log("Send Private Message"),
    },
    {
      title: "Remove from Space",
      action: () => console.log("Remove from Space"),
    },
    {
      title: "Report",
      action: () => console.log("Report"),
    },
    {
      title: "Block",
      action: () => console.log("Block"),
      color: "text-red-600",
      darkColor: "dark:text-red-400",
    },
  ];
  return (
    <HStack
      space={2}
      justifyContent="between"
      alignItems="center"
      className="gap-2 flex-row"
    >
      <HStack
        space={2}
        className="gap-2 flex-row w-full flex-wrap"
        justifyContent="center"
      >
        {space?.users
          // if showMessages is true, slice the first 10 users, otherwise all users
          .slice(0, showMessages ? 5 : space?.users.length)
          .map((user: any, index: any) => (
            <Stack
              space={2}
              className="gap-1"
              justifyContent="center"
              alignItems="center"
              key={index}
            >
              <Menu>
                <MenuButton>
                  <Avatar src={user?.avatar} size={64} />
                </MenuButton>
                <MenuList className="w-52 shadow-lg">
                  <HStack space={2} alignItems="center" className="p-3">
                    <Avatar src={user?.avatar} size={54} />
                    <Stack>
                      <Text weight="bold">{user?.name}</Text>
                      <Text theme="muted" size="sm">
                        {user?.role}
                      </Text>
                    </Stack>
                  </HStack>
                  <Stack className="mt-5 p-2">
                    {menus.map((menu, index) => (
                      <div
                        key={index}
                        className="hover:bg-[#c5c5c5] rounded cursor-pointer dark:hover:bg-[#3a3a3a] p-2"
                      >
                        <Text
                          className={`ml-1 ${menu.color} ${menu.darkColor}`}
                        >
                          {menu.title}
                        </Text>
                      </div>
                    ))}
                  </Stack>
                </MenuList>
              </Menu>
              <div className="flex flex-col justify-center items-center">
                <Text weight="bold">{user?.name}</Text>
                <Text theme="muted" size="sm">
                  {user?.role}
                </Text>
              </div>
            </Stack>
          ))}
      </HStack>
    </HStack>
  );
};

export default Members;
