import React, { createContext, useContext, useState } from "react";

const PiPContext = createContext<any>({ showPiP: false, setShowPiP: () => {} });

export const usePiP = () => useContext(PiPContext);

export const PiPProvider = ({ children }: any) => {
  const [showPiP, setShowPiP] = useState(false);

  return (
    <PiPContext.Provider value={{ showPiP, setShowPiP }}>
      {children}
    </PiPContext.Provider>
  );
};
