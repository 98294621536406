import React, { useState } from "react";
import { Avatar, Button, HStack, IconButton, Input, Stack, Text } from "./ui";
import Members from "soapbox/features/ui/components/modals/manage-space-modal/members";
import StatusActionButton from "./status-action-button";
import { shortNumberFormat } from "soapbox/utils/numbers";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  back: { id: "card.back.label", defaultMessage: "Back" },
  close: { id: "lightbox.close", defaultMessage: "Close" },
  confirm: { id: "confirmations.delete.confirm", defaultMessage: "Delete" },
});

const PiP = () => {
  const [joined, setJoined] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false); // New state for minimizing the card
  const [isVisible, setIsVisible] = useState(true);
  const [showMessages, setShowMessages] = useState(false);
  const closeIcon = require("@tabler/icons/outline/x.svg");
  const intl = useIntl();

  const space = {
    id: "1",
    name: "Space 1",
    description: "Short description of the space goes here",
    users: [
      {
        name: "User 1",
        role: "Host",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
      {
        name: "User 2",
        role: "Speaker",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
      {
        name: "User 3",
        role: "Listener",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
      {
        name: "User 4",
        role: "Listener",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
      {
        name: "User 5",
        role: "Listener",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
      {
        name: "User 6",
        role: "Listener",
        avatar:
          "https://nyc3.digitaloceanspaces.com/gotvoiced/accounts/avatars/112/503/182/528/199/754/original/acc54839430bfeb7.jpg",
      },
    ],
  };

  const handleSubmit = () => {
    setJoined(!joined);
    setIsJoin(!isJoin);
  };

  const onLeave = () => {
    setJoined(!joined);
    setIsJoin(!isJoin);
    setIsVisible(false);
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMessageClick = () => {
    setShowMessages(!showMessages);
  };

  const likes = 1000;
  const comments = 1000;

  if (!isVisible) return null;

  return (
    <>
      {!isMinimized ? (
        <div className="fixed bottom-4 right-4 bg-white dark:bg-black dark:text-white rounded-lg shadow-xl z-50 w-full max-w-lg transition-opacity duration-300 ease-in-out transform translate-y-0">
          <div className="p-4 sm:p-8">
            <div className="flex justify-between mb-4">
              <Stack
                justifyContent="center"
                alignItems="center"
                className="ml-0 sm:ml-20"
              >
                <h2 className="text-xl sm:text-2xl">{space.name}</h2>
                <Text size="sm" theme="muted">
                  {space.description}
                </Text>
              </Stack>
              <>
                <Button
                  theme="danger"
                  onClick={onLeave}
                  className="rounded-none bg-transparent border border-red-500 text-red-500 hover:bg-red-500 hover:text-white h-10 dark:rounded-none dark:bg-transparent dark:border dark:border-red-400 dark:text-red-400 dark:hover:bg-red-400 dark:hover:text-white"
                >
                  Leave
                </Button>
              </>
            </div>
            <div className="space-y-4 mb-4">
              <Stack space={3} className="my-5" alignItems="center">
                <Members space={space} showMessages={showMessages} />
              </Stack>
            </div>
          </div>
          <hr className="text-black dark:text-white" />
          <Stack
            alignItems="center"
            justifyContent="center"
            className="gap-3 w-full p-2 sm:p-4"
          >
            {joined ? (
              <>
                <Text size="sm" theme="muted" className="mt-2">
                  Your mic will be off to start
                </Text>

                <Button theme={"primary"} onClick={handleSubmit} block>
                  Join Space
                </Button>
              </>
            ) : (
              <>
                {showMessages && (
                  <div
                    className={`bg-white dark:bg-gray-800 dark:text-white transition-transform duration-300 transform w-full ${
                      showMessages
                        ? "translate-y-0 opacity-100"
                        : "translate-y-10 opacity-0"
                    }`}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-end">
                        <IconButton
                          src={closeIcon}
                          title={intl.formatMessage(messages.close)}
                          onClick={handleMessageClick}
                          className="text-gray-500 hover:text-gray-700 rtl:rotate-180 dark:text-gray-300 dark:hover:text-gray-200"
                        />
                      </div>
                      <div className="space-y-4 mb-4">
                        <Stack className="space-y-1">
                          <HStack alignItems="center" space={2}>
                            <Avatar src={space.users[0].avatar} size={30} />
                            <Text size="md" weight="bold">
                              username
                            </Text>
                          </HStack>
                          <Text
                            size="xs"
                            className="bg-[#D9D9D9] rounded-full p-1.5 w-[fit-content] dark:bg-gray-700 ml-8"
                          >
                            I agree wih you
                          </Text>
                        </Stack>

                        <Stack className="space-y-1">
                          <HStack alignItems="center" space={2}>
                            <Avatar src={space.users[0].avatar} size={30} />
                            <Text size="md" weight="bold">
                              username
                            </Text>
                          </HStack>
                          <Text
                            size="xs"
                            className="bg-[#D9D9D9] rounded-full p-1.5 w-[fit-content] dark:bg-gray-700 ml-8"
                          >
                            I agree wih you
                          </Text>
                        </Stack>

                        <Stack className="space-y-1">
                          <HStack alignItems="center" space={2}>
                            <Avatar src={space.users[0].avatar} size={30} />
                            <Text size="md" weight="bold">
                              username
                            </Text>
                          </HStack>
                          <Text
                            size="xs"
                            className="bg-[#D9D9D9] rounded-full p-1.5 w-[fit-content] dark:bg-gray-700 ml-8"
                          >
                            I agree wih you
                          </Text>
                        </Stack>
                      </div>
                    </div>
                  </div>
                )}
                {!showMessages && (
                  <HStack className={`w-full gap-2`} justifyContent="end">
                    <HStack className="gap-2">
                      <StatusActionButton
                        icon={require("@tabler/icons/outline/share.svg")}
                      />
                      <StatusActionButton
                        icon={
                          likes > 0
                            ? require("@tabler/icons/filled/heart.svg")
                            : require("@tabler/icons/outline/heart.svg")
                        }
                        text={likes > 0 ? shortNumberFormat(likes) : "Like"}
                      />
                      <StatusActionButton
                        icon={require("@tabler/icons/outline/message-2.svg")}
                        text={
                          comments > 0 ? shortNumberFormat(comments) : "Comment"
                        }
                        onClick={handleMessageClick}
                      />
                    </HStack>
                  </HStack>
                )}
              </>
            )}
          </Stack>
          {showMessages && (
            <div
              className={`mb-3 transition-transform duration-300 transform ${
                showMessages
                  ? "translate-y-0 opacity-100"
                  : "translate-y-10 opacity-0"
              }`}
            >
              <hr className="text-black dark:text-white mb-5" />
              <div className="relative mx-6">
                <Input
                  placeholder="Type message"
                  className="mx-1 w-[94%] max-w-lg rounded-t-full rounded-b-full outline-none border-none bg-gray-100 dark:bg-gray-700 p-3"
                />
                <IconButton
                  className="absolute right-[73px] top-[6px] rounded-full"
                  src={require("@tabler/icons/outline/mood-smile.svg")}
                />
                <IconButton
                  className="absolute right-9 top-[6px] rounded-full"
                  src={require("@tabler/icons/outline/send-2.svg")}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <IconButton
          src={require("@tabler/icons/outline/plus.svg")}
          className="fixed bottom-7 right-4 bg-[#40C2DA] text-white dark:bg-black dark:text-white rounded-full shadow-xl z-10 w-16 h-16 flex items-center justify-center cursor-pointer border"
          onClick={toggleMinimize}
          theme="transparent"
        />
      )}
    </>
  );
};

export default PiP;
